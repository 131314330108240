import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Head from "components/Head"
import GridAside from "components/GridAside"
import CardPost from "components/CardPost"

const UpdatedPage = ({ data }) => (
  <Layout>
    <Head id="published" />
    <GridAside>
      <div className="card is-separate is-overflow-hidden">
        <div className="card-header">
          <div className="inner">
            <h2 className="heading is-dark-2 is-strong is-sm">
              First Published
            </h2>
          </div>
        </div>
        <ul className="card-list is-separate">
          {data.posts.edges.map((edge, i) => (
            <li key={i}>
              <CardPost
                to={`/posts/${edge.node.data.slug}/`}
                title={edge.node.data.title}
                icon_style={edge.node.data.tags[0].data.icon_style}
                icon_name={edge.node.data.tags[0].data.icon_name}
                icon_color={edge.node.data.tags[0].data.icon_color}
                status={edge.node.data.status}
                publishedAt={edge.node.data.publishedAt}
                updatedAt={edge.node.data.updatedAt}
              />
            </li>
          ))}
        </ul>
      </div>
    </GridAside>
  </Layout>
)

export default UpdatedPage

export const query = graphql`
  query {
    posts: allAirtable(
      filter: { table: { eq: "posts" }, data: { published: { eq: true } } }
      sort: { fields: [data___publishedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            updatedAt
            status
            tags {
              data {
                name
                slug
                icon_style
                icon_name
                icon_color
              }
            }
          }
        }
      }
    }
  }
`
